<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(saveChart)">
      <b-row>
        <b-col md="12" class="mb-2">
          <input-form v-model="account.title" name='Account Name' label="Account Name" validate="required"></input-form>
        </b-col>
        <b-col md="12" class="mb-2">
          <input-form v-model="account.code" name='Account Code' label="Account Code" validate="required"></input-form>
        </b-col>
        <b-col md="12" class="mb-2" v-if="selected === 'Parent'">
          <label>Type</label>
          <div class="periodNumbers flex-wrap">
                        <span v-for="(type, key) in types" :key="key" :class="[{ 'shadow selectedNumber' :
                        isSelected(type)},'d-flex justify-content-center align-items-center  my-1']"
                              @click="selectedType = type">
                          {{type}}
                        </span>
          </div>
        </b-col>
        <b-col md="12" class="mb-2">
          <input-form v-model="account.depresiation_period" name="Depresiation Period" label="Depresiation Period"
                      validate="required"></input-form>
        </b-col>
        <b-col md="12" class="mb-2">
          <input-form v-model="account.depresiation_expense_gl" name="Depresiation Expense Gl"
                      label="Depresiation Expense Gl" validate="required"></input-form>
        </b-col>
        <b-col md="12" class="mb-2">
          <div class="d-flex  mt-4 align-items-center justify-content-between">
            <b-form-checkbox v-model="account.is_active">Is Active</b-form-checkbox>
            <b-button  class="container_button_blue slideNextArrow" type="submit" variant="primary"
                      v-if="!loadingButton">
              Save
            </b-button>
            <b-button class="container_button_blue slideNextArrow" variant="primary" v-else>
              <spinner-loading text="Saving" />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'newChartOfAccount',
  props: ['selected', 'loadingButton', 'parent'],
  data () {
    return {
      account: {
        title: '',
        code: '',
        is_active: false,
        depresiation_period: '',
        depresiation_expense_gl: ''
      },
      types: ['credit', 'debit'],
      selectedType: 'credit'
    }
  },
  methods: {
    isSelected (i) {
      // eslint-disable-next-line eqeqeq
      return i == this.selectedType
    },
    saveChart () {
      if (this.selected === 'Parent') {
        this.$emit('saveChart', { ...this.account, type: this.selectedType })
      } else {
        this.$emit('saveChart', { ...this.account, parent: this.parent })
      }
    }
  }
}
</script>

<style scoped>
.periodNumbers span {
  cursor: pointer;
  background: #ececec;
  padding: 5px 15px;
  border-radius: 10px;
  margin-right: 10px;
  color: #5b5b5b;
}
.selectedNumber{
  color: var(--iq-white) !important;
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%) !important;
}
</style>
