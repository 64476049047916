<template>
  <b-container fluid>
    <b-modal id="addNewChart" size="lg" title="Create New" hide-footer no-close-on-backdrop>
      <chart-of-account-details :loadingButton="loadingButton"
                                :parent="parentId"
                                :selected="selected"
                                @saveChart="createCategory"/>
    </b-modal>
    <div class="overflow-auto">
      <div class="allChart">
        <div  v-for="(item,key) in chartOfAccount" :key="key" class= "mx-1">
          <iq-card v-if="item.show" class="width-400">
            <template v-slot:headerTitle>
              <h4 class="card-title">{{item.name}}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button variant="primary" @click="openModal(item.name, key)">Add New</b-button>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table striped responsive :items="item.data" :fields="key === 0 ? parentColumns : columns ">
                    <template v-slot:cell(action)="data">
                      <b-button variant=" iq-bg-success mr-1 mb-1" size="sm"  v-if="chartOfAccount.length !== key + 1" @click="show(data.item, key)"><i class="ri-eye-fill m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import chartOfAccountDetails from '../components/newChartOfAccount'
import settingService from '../services/settings'
export default {
  name: 'chartOfAccount',
  components: { chartOfAccountDetails },
  data () {
    return {
      columns: [
        { label: 'Title', key: 'title', class: 'text-left' },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      parentColumns: [
        { label: 'Title', key: 'title', class: 'text-left' },
        { label: 'Type', key: 'type', class: 'text-center' },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      chartOfAccount: [
        {
          name: 'Parent',
          data: [],
          show: true
        },
        {
          name: 'Child 1',
          data: [],
          show: false
        },
        {
          name: 'Child 2',
          data: [],
          show: false
        },
        {
          name: 'Child 3',
          data: [],
          show: false
        },
        {
          name: 'Child 4',
          data: [],
          show: false
        }
      ],
      selected: '',
      loadingButton: false,
      openIndex: null,
      parentId: '',
      key: ''
    }
  },
  methods: {
    openModal (name, index) {
      this.selected = name
      this.openIndex = 1
      this.$bvModal.show('addNewChart')
    },
    createCategory (data) {
      this.loadingButton = true
      settingService.createCategory(data).then(res => {
        if (data.parent) {
          settingService.getAllCatgoryByParent(data.parent ? data.parent : 0).then(res => {
            this.chartOfAccount[this.key + 1].data = res.data.data.child
          })
        } else {
          this.getAllCategory()
        }
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('addNewChart')
      }).finally(() => {
        this.loadingButton = false
      })
    },
    getAllCategory () {
      settingService.getAllCatgory().then(res => {
        this.chartOfAccount[0].data = res.data.data
      })
    },
    show (item, key) {
      this.parentId = item.id
      this.key = key
      this.chartOfAccount[key + 1].name = `child of ${item.title}`
      this.chartOfAccount[key + 1].show = true
      settingService.getAllCatgoryByParent(item.id).then(res => {
        this.chartOfAccount[key + 1].data = res.data.data.child
      })
    }
  },
  created () {
    this.getAllCategory()
  },
  mounted () {
    core.index()
  }
}
</script>

<style scoped>
.width-400{
  width: 400px;
}
.allChart {
  white-space: nowrap;
  display: inline-flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
